<template>
  <a-modal v-model:visible="modalVisible" :footer="null" width="800px">
    <template #title>
      <div class="modalTitle" :style="{ borderColor: store.state.themeColor }">
        {{ pageState.title }}
      </div>
    </template>
    <template #extra>
      <a href="#">编辑</a>
    </template>
    <div class="content">
      <a-form
        ref="formRef"
        :rules="rules"
        :model="formState"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        labelAlign="right"
      >
        <a-form-item name="name" label="名称">
          <a-input v-model:value="formState.name" />
        </a-form-item>

        <a-form-item label="标签">
          <a-select
            v-model:value="formState.tags"
            mode="multiple"
            placeholder="可选择标签"
            :options="formOpts.tags"
            @search="handleTagsChange"
          />
        </a-form-item>
        <a-form-item label="所属教材章节" name="book_id">
          <a href="#" @click="() => (pageState.modalVisible = true)">{{
            formOpts.bookOpts.showName
          }}</a>
          <a-button style="margin-left: 24px" @click="handleReset">重置教材</a-button>
        </a-form-item>
      </a-form>
    </div>
    <div class="footer">
      <a-button
        type="primary"
        size="large"
        class="submitBtn"
        @click="handleSubmit"
      >
        确定
      </a-button>
    </div>
  </a-modal>
  <select-book-modal
    ref="modalRef"
    :type="bookType"
    v-model:visible="pageState.modalVisible"
    @ok="handleSelectBookSection"
  />
</template>

<script>
import { reactive, watch, ref, computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { getTags, getTeacherTags } from '@/services/common'
import SelectBookModal from '@/views/songs/components/SelectBookModal'
import {
  createKnowledgePoint,
  createPersonalKnowledgePoint,
  setKnowledgePointsTags,
  createPersonalTags,
  editKnowledgePoint,
  editPersonalKnowledgePoint,
  getPersonalKnowledgePointInfo,
  getKnowledgePointInfo
} from '@/services/knowledge-points'
import { debounce } from '@/utils/common'
import { message } from 'ant-design-vue'

export default {
  name: 'Form',
  props: {
    visible: Boolean,
    current: Object,
    type: {
      type: Number,
      default: 1
    }
  },
  components: {
    SelectBookModal
  },
  emits: ['onOk'],
  setup (props, { emit }) {
    const formRef = ref()
    const bookType = computed(() => parseInt(props.type))
    const pageState = reactive({
      title: '创建知识点',
      modalVisible: false
    })
    const formState = ref({})
    const formOpts = reactive({
      tags: [],
      bookOptions: [],
      bookOpts: {
        showName: '教材名称-章节目录'
      },
      defaultBookOpts: {
        showName: '教材名称-章节目录'
      }
    })

    watch(() => props.current, () => {
      formState.value = props.current
      pageState.title = formState.value.id ? '编辑知识点' : '创建知识点'
      formOpts.bookOpts.showName = formOpts.defaultBookOpts.showName
      if (formState.value.id && formState.value.book) {
        formOpts.bookOpts.showName = `${formState.value.book.name}`
        if (formState.value.section) {
          formOpts.bookOpts.showName += `-${formState.value.section.name}`
        }
      }
    }, { immediate: true })

    const store = useStore()

    const modalVisible = computed({
      get: () => props.visible,
      set: val => {
        emit('update:visible', val)
      }
    })

    const rules = {
      name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
    }

    const handleTagsChange = value => {
      console.log(`selected ${value}`)
      getTagsOptions({ keyword: value }, { label: value, value })
    }

    const getTagsOptions = debounce(async (params, addItem) => {
      const defaultParams = { per_page: 50 }
      params = { ...defaultParams, ...params }
      const getTagsService = bookType.value === 1 ? getTags : getTeacherTags
      formOpts.tags = (await getTagsService(params)).items.map(i => {
        i.label = i.value = i.tag
        return i
      })
      if (formOpts.tags.length === 0 && addItem) {
        formOpts.tags.push(addItem)
      }
    })

    const getContentById = async (id) => {
      const func = bookType.value === 1 ? getKnowledgePointInfo : getPersonalKnowledgePointInfo
      const data = await func(id)
      const content = data.content
      return content
    }

    const handleSelectBookSection = (value) => {
      pageState.modalVisible = false
      if (value.values.length > 0) {
        formOpts.bookOpts.showName = value.labels.join('-')
        pageState.book_section_id = value.values
        formState.value.book_id = pageState.book_section_id[0]
        delete formState.value.book_section_id
        if (value.values.length > 1) {
          formState.value.book_section_id = pageState.book_section_id[pageState.book_section_id.length - 1]
        }
      } else {
        formOpts.bookOpts.showName = formOpts.defaultBookOpts.showName
      }
    }

    const handleSubmit = async () => {
      formRef.value.validate().then(async () => {
        const params = {
          name: formState.value.name,
          content: formState.value.content || '',
          book_id: formState.value.book_id || 0,
          book_section_id: formState.value.book_section_id || 0
        }
        if (!params.book_id) {
          message.error('请选择教材')
          return
        }
        const createService = bookType.value === 1 ? createKnowledgePoint : createPersonalKnowledgePoint
        const setTagService = bookType.value === 1 ? setKnowledgePointsTags : createPersonalTags
        const editService = bookType.value === 1 ? editKnowledgePoint : editPersonalKnowledgePoint
        if (!formState.value.id) {
          const { id } = await createService(params)
          await setTagService(id, {
            tags: formState.value.tags
          })
        } else {
          const content = await getContentById(formState.value.id)
          params.content = content
          await editService(formState.value.id, params)
          await setTagService(formState.value.id, {
            tags: formState.value.tags
          })
        }
        emit('onOk')
      }).catch(e => {
        console.log(e)
      })
    }

    const handleReset = () => {
      formOpts.bookOpts.showName = formOpts.defaultBookOpts.showName
      formState.value.book_section_id = 0
      formState.value.book_id = 0
    }

    onBeforeMount(() => {
      getTagsOptions()
    })

    return {
      rules,
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 18
      },
      formRef,
      formState,
      pageState,
      store,
      modalVisible,
      formOpts,
      bookType,
      handleTagsChange,
      handleSelectBookSection,
      handleSubmit,
      handleReset
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  padding: 0 18px;
}
.modalTitle {
  margin-top: 10px;
  font-size: 20px;
  border-left: 3px solid;
  padding-left: 20px;
}
.footer {
  text-align: center;
  margin-top: 12px;
  .submitBtn {
    width: 118px;
    height: 40px;
    border-radius: 4px;
  }
}
</style>
