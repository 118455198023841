<template>
  <div class="searchDiv">
    <search-bar v-model:value="formState.name" :on-search="getList" />
  </div>
  <div v-show="!pageData.firstLoading">
    <div class="formDiv">
      <div>
        标签 ：<a-select
        v-model:value="formState.tag"
        mode="multiple"
        placeholder="可选择标签"
        style="width: 300px"
        :options="formOpts.tags"
        @search="handleTagSearch"
        @change="getList"
      />
      </div>
      <div>
        所属教材章节 :
        <a href="#" @click="() => pageData.modalVisible = true">{{formOpts.bookOpts.showName}}</a>
      </div>
      <div>
        <a-button @click="handleReset">重置</a-button>
      </div>
    </div>
    <div class="tableDiv">
      <a-tabs v-model:activeKey="pageData.type" @change="handleTypeChange" >
        <a-tab-pane v-for="tab in tabList" :key="tab.key" :tab="tab.title"></a-tab-pane>
        <template #tabBarExtraContent>
          <a-button
            v-permission-in-slot="PermissionMap.KNOWLEDGE_POINTS_LIST"
            style="flex: 1"
            class="btn"
            type="primary"
            @click="handleOpenForm()"
            v-if="pageData.type === 1"
          >
            创建知识点
          </a-button>
          <a-button
            v-permission-in-slot="PermissionMap.KNOWLEDGE_POINTS_PERSONAL_CREATE"
            style="flex: 1"
            class="btn"
            type="primary"
            @click="handleOpenForm()"
            v-if="pageData.type === 2"
          >
            创建知识点
          </a-button>
        </template>
      </a-tabs>
      <a-table
        :dataSource="tableState.data"
        :columns="tableState.columns"
        rowKey="id"
        :pagination="tableState.pagination"
        :loading="tableState.loading"
        @change="handleTableChange"
        :scroll="{ x: 1500 }"
      >
        <template #action="{ record }">
          <div v-if="pageData.type === 1">
            <router-link
              v-permission="PermissionMap.KNOWLEDGE_POINTS_INFO"
              :to="`/resource/knowledge-points/${record.id}/content/1`"
            >
              查看知识点
            </router-link>
            <a-divider
              v-permission="PermissionMap.KNOWLEDGE_POINTS_INFO"
              type="vertical"
            />
            <a
              v-permission="PermissionMap.KNOWLEDGE_POINTS_COPY"
              href="#"
              @click="handleCopy(record)"
            >
              复制到我的知识点
            </a>
            <a-divider
              v-permission="PermissionMap.KNOWLEDGE_POINTS_COPY"
              type="vertical"
            />
            <a
              v-permission="PermissionMap.KNOWLEDGE_POINTS_EDIT"
              @click="handleOpenForm(record)"
            >
              编辑
            </a>
            <a-divider
              v-permission="PermissionMap.KNOWLEDGE_POINTS_EDIT"
              type="vertical"
            />
            <a-popconfirm
              title="确定删除该知识点吗?"
              ok-text="删除"
              cancel-text="取消"
              @confirm="handleDelete(record)"
            >
              <a href="#" v-permission="PermissionMap.KNOWLEDGE_POINTS_DELETE">删除</a>
            </a-popconfirm>
          </div>

          <div v-if="pageData.type === 2">
            <router-link
              v-permission="PermissionMap.KNOWLEDGE_POINTS_PERSONAL_INFO"
              :to="`/resource/knowledge-points/${record.id}/content/2`"
            >
              查看知识点
            </router-link>
            <a-divider
              v-permission="PermissionMap.KNOWLEDGE_POINTS_PERSONAL_INFO"
              type="vertical"
            />
            <a
              v-permission="PermissionMap.KNOWLEDGE_POINTS_PERSONAL_EDIT"
              href="#"
              @click="handleOpenForm(record)"
            >
              编辑
            </a>
            <a-divider
              v-permission="PermissionMap.KNOWLEDGE_POINTS_PERSONAL_EDIT"
              type="vertical"
            />
            <a-popconfirm
              title="确定删除该知识点吗?"
              ok-text="删除"
              cancel-text="取消"
              @confirm="handleDelete(record)"
            >
              <a href="#" v-permission="PermissionMap.KNOWLEDGE_POINTS_PERSONAL_DELETE">删除</a>
            </a-popconfirm>
          </div>
        </template>
        <template #songType="{ text }">
          <a-tag :color="songType.get(text).presetColor">{{
              songType.get(text).name
            }}</a-tag>
        </template>
        <template #tags="{ text }">
          <span v-if="text && !text.length">--</span>
          <span v-else>
            <a-tooltip :title="text.map((tItem) => tItem.tag).toString()" placement="topLeft">
              <span :key="item.tag" v-for="item in text">
                <a-tag class="tag_item_label">{{ item.tag }}</a-tag>
              </span>
            </a-tooltip>
          </span>
        </template>
      </a-table>
    </div>
  </div>
  <a-skeleton v-if="pageData.firstLoading" />
  <select-book-modal
    ref="modalRef"
    :type="pageData.type"
    v-model:visible="pageData.modalVisible"
    @ok="handleSelectBookSection"
  />
  <edit-form
    :type="pageData.type"
    :current="pageData.current"
    v-model:visible="pageData.formModalVisible"
    @onOk="handleOk"
  />
</template>

<script>
import SearchBar from '@/components/common/SearchBar'
import { message } from 'ant-design-vue'
import { computed, onBeforeMount, reactive, ref } from 'vue'
import EditForm from './Form'

import { getTags, getTeacherTags } from '@/services/common'
import {
  getKnowledgePoints,
  deleteKnowledgePoint,
  getPersonalKnowledgePoint,
  deletePersonalKnowledgePoint,
  copyKnowledgePoint
} from '@/services/knowledge-points'
import { debounce } from '@/utils/common'
import { useRouter } from 'vue-router'
import { filterPermiison, PermissionMap } from '@/utils/auth'
import SelectBookModal from '@/views/songs/components/SelectBookModal'

export default {
  name: 'KnowledgePoints',
  components: {
    SearchBar,
    SelectBookModal,
    EditForm
  },
  setup () {
    const modalRef = ref()
    const pageData = reactive({
      firstLoading: true,
      advanced: false,
      type: 1,
      tagParams: {
        pageSize: 50,
        page: 1,
        keyword: ''
      },
      modalVisible: false,
      formModalVisible: false,
      current: {}
    })
    const formState = reactive({
      name: '',
      book_section_id: [],
      tag: []
    })
    const formOpts = reactive({
      bookOptions: [],
      bookOpts: {
        showName: '教材名称-章节目录'
      },
      defaultBookOpts: {
        showName: '教材名称-章节目录'
      }
    })

    const tableState = reactive({
      data: [],
      columns: [],
      loading: true,
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
        showTotal: total => `共${total}条`,
        showQuickJumper: true
      }
    })

    tableState.columns = [
      {
        title: '名称',
        dataIndex: 'name',
        ellipsis: true
      },
      {
        title: '教材',
        dataIndex: 'book.name',
        ellipsis: true
      },
      {
        title: '教材出版社',
        dataIndex: 'book.press_version.name',
        ellipsis: true
      },
      {
        title: '章节',
        dataIndex: 'section_breadcrumb',
        ellipsis: true
      },
      {
        title: '标签',
        dataIndex: 'tags',
        ellipsis: true,
        slots: { customRender: 'tags' }
      },
      {
        title: '创建时间',
        dataIndex: 'created_at',
        ellipsis: true
      },
      {
        title: '修改时间',
        dataIndex: 'updated_at',
        ellipsis: true
      },
      {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 230,
        slots: { customRender: 'action' }
      }
    ]

    // tab页动态渲染数组
    const tabList = filterPermiison([
      {
        key: 1,
        title: '知识点列表'
      }
    ])

    const router = useRouter()

    const getList = debounce(async (params) => {
      const getSheetSong = pageData.type === 1 ? getKnowledgePoints : getPersonalKnowledgePoint
      tableState.loading = true
      params = { ...queryParams.value, page: 1, ...params }
      const { items, pagination } = await getSheetSong(params)
      tableState.data = items
      tableState.loading = false
      tableState.pagination = { ...tableState.pagination, ...pagination }
    })

    const handleTableChange = (pag, filters, sorter) => {
      tableState.pagination = pag
      getList({ page: pag.current })
    }

    const handleReset = () => {
      modalRef.value.handleReset()
      formState.name = ''
      formState.book_section_id = []
      formState.tag = []
      formOpts.bookOpts.showName = formOpts.defaultBookOpts.showName
      getTagsOptions()
      getList()
    }

    const queryParams = computed(() => {
      const params = { ...formState }
      if (params.song_type === -1) {
        delete params.song_type
      }
      if (params.song_code === '') {
        delete params.song_code
      }
      if (params.py === '') {
        delete params.py
      }
      if (params.book_section_id.length === 0) {
        delete params.book_section_id
      } else if (params.book_section_id.length === 1) {
        params.book_id = params.book_section_id[0]
        delete params.book_section_id
      } else {
        params.book_id = params.book_section_id[0]
        params.book_section_id = params.book_section_id[params.book_section_id.length - 1]
      }
      for (const key in params) {
        if (params[key] === '') {
          delete params[key]
        }
      }
      params.per_page = tableState.pagination.pageSize
      params.page = tableState.pagination.current
      return params
    })

    const getTagsOptions = debounce(async () => {
      const params = {
        per_page: pageData.tagParams.pageSize,
        current: pageData.tagParams.page,
        keyword: pageData.tagParams.keyword
      }
      const getTagsService = pageData.type === 1 ? getTags : getTeacherTags
      formOpts.tags = (await getTagsService(params)).items.map(i => {
        i.label = i.tag
        i.value = i.tag
        return i
      })
    })

    const handleDelete = async record => {
      const deleteService = pageData.type === 1 ? deleteKnowledgePoint : deletePersonalKnowledgePoint
      await deleteService(record.id)
      message.success('删除成功')
      getList({ page: tableState.pagination.current })
    }

    const formatCascaderChildrenOpts = (children) => {
      return children.map(i => {
        const item = {
          label: i.name,
          value: i.id
        }
        if (i.children) {
          item.children = formatCascaderChildrenOpts(i.children)
        }
        return item
      })
    }

    const handleOpenForm = async (record = {}) => {
      pageData.formModalVisible = true
      pageData.current = {
        ...record,
        tags: record.tags?.map(i => i.tag)
      }

      // router.push(`/knowledge-points/0/create/${pageData.type}`)
      // router.push('/sheet-songs')
    }

    const handleTagSearch = v => {
      pageData.tagParams.page = 1
      pageData.tagParams.keyword = v
      getTagsOptions()
    }

    const handleSelectBookSection = (value) => {
      pageData.modalVisible = false
      if (value.values.length > 0) {
        formOpts.bookOpts.showName = value.labels.join('-')
        formState.book_section_id = value.values
      } else {
        formOpts.bookOpts.showName = formOpts.defaultBookOpts.showName
      }
      getList()
    }

    const handleTypeChange = () => {
      getList()
      getTagsOptions()
    }

    const handleCopy = async (record) => {
      const key = 'copy'
      try {
        await copyKnowledgePoint(record.id)
        message.loading({
          key,
          content: '正在处理'
        })
        message.success({
          key,
          content: '复制成功'
        })
      } catch (e) {
        message.error({
          key,
          content: '操作失败'
        })
      }
    }

    const handleOk = () => {
      pageData.formModalVisible = false
      message.success('操作成功')
      getList()
    }

    onBeforeMount(async () => {
      await Promise.all([
        getList(),
        getTagsOptions()
      ])
      pageData.firstLoading = false
    })

    return {
      modalRef,
      pageData,
      formState,
      formOpts,
      tableState,
      getList,
      tabList,
      PermissionMap,
      handleTableChange,
      handleDelete,
      handleOpenForm,
      handleReset,
      handleTagSearch,
      handleSelectBookSection,
      handleTypeChange,
      handleCopy,
      handleOk
    }
  }
}
</script>

<style lang="less" scoped>
.searchDiv {
  width: 100%;
  padding-top: 26px;
  padding-bottom: 22px;
  background-color: #ffffff;
}
.formDiv {
  background-color: white;
  margin: 30px 30px 0 30px;
  padding: 20px 38px;
  div {
    margin-top: 10px;
  }
}
.tableDiv {
  background-color: white;
  margin: 30px 30px 0 30px;
  padding: 20px 38px;
  .iconExtra {
    display: inline-block;
    font-size: 24px;
    margin: 2px 6px 0 0;
  }
  .cardTableDiv {
    padding-left: 20px;
  }
  .pagination {
    margin-top: 20px;
    text-align: right;
  }
}
.ant-col {
  margin-top: 16px;
}
.tableOperator {
  margin-top: 16px;
}

.tag_item_label {
  max-width: 100px;
  overflow: hidden; //超出一行文字自动隐藏
  text-overflow: ellipsis; //文字隐藏后添加省略号
  white-space: nowrap; //强制不换行
  cursor: default;
}
</style>
